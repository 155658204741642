export const actions = {
  AUTHENTICATE: "AUTHENTICATE",
  AUTHENTICATING: "AUTHENTICATING",
  REQUEST_FILE: "REQUEST_FILE",
  PROCESS_FILE: "PROCESS_FILE",
  USER_FILES: "USER_FILES",
  ALL_FILES: "ALL_FILES",
  ACTIVE_FILE: "ACTIVE_FILE",
  REFETCH_FIVE: "REFETCH_FIVE",
  REFETCH_ALL: "REFETCH_ALL",
  USER_OPT: "USER_OPT",
  NAV: "NAV",
  CLEAR: "CLEAR",
};

export const initialState = {
  isAuthenticated: false,
  isAuthenticating: true,
  requestedFile: {
    fileName: null,
    filePrice: 0,
  },
  processedFile: {
    fileName: null,
    filePath: null,
  },
  userFiles: null,
  allFiles: null,
  activeFile: null,
  refetchTopFive: true,
  refetchAllFiles: true,
  userOptIn: null,
  nav: "About code-sense",
};

const omit = (prop, { [prop]: __dirname, ...rest }) => rest;
const clearedState = omit("isAuthenticated", initialState);
const finalClearedState = omit("isAuthenticating", clearedState);

const reducer = (state, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case actions.AUTHENTICATE: {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    }
    case actions.AUTHENTICATING: {
      return {
        ...state,
        isAuthenticating: action.payload,
      };
    }
    case actions.REQUEST_FILE: {
      return {
        ...state,
        requestedFile: action.payload,
      };
    }
    case actions.PROCESS_FILE: {
      return {
        ...state,
        processedFile: action.payload,
      };
    }
    case actions.USER_FILES: {
      return {
        ...state,
        userFiles: action.payload,
      };
    }
    case actions.ALL_FILES: {
      return {
        ...state,
        allFiles: action.payload,
      };
    }
    case actions.ACTIVE_FILE: {
      return {
        ...state,
        activeFile: action.payload,
      };
    }
    case actions.REFETCH_FIVE: {
      return {
        ...state,
        refetchTopFive: action.payload,
      };
    }
    case actions.REFETCH_ALL: {
      return {
        ...state,
        refetchAllFiles: action.payload,
      };
    }
    case actions.USER_OPT: {
      return {
        ...state,
        userOptIn: action.payload,
      };
    }
    case actions.NAV: {
      return {
        ...state,
        nav: action.payload,
      };
    }
    case actions.CLEAR: {
      return {
        ...state,
        ...finalClearedState,
      };
    }
  }
};

export default reducer;
