export const getRequestedSession = (initialState) => {
  const sessionFile = localStorage.getItem("requestedFile");
  if (sessionFile) {
    const sessionData = JSON.parse(sessionFile);
    if (new Date().getTime() > sessionData.expiry) {
      localStorage.removeItem("requestedFile");
      return initialState;
    } else {
      return {
        fileName: sessionData.value.file,
        filePrice: sessionData.value.cost,
      };
    }
  } else {
    return initialState;
  }
};

export const getProcessedSession = (initialState) => {
  const sessionFile = localStorage.getItem("processedFile");
  if (sessionFile) {
    const sessionData = JSON.parse(sessionFile);
    if (new Date().getTime() > sessionData.expiry) {
      localStorage.removeItem("processedFile");
      return initialState;
    } else {
      const { fileName, filePath } = sessionData.value;
      return { fileName, filePath };
    }
  } else {
    return initialState;
  }
};
