import { useEffect, useReducer } from "react";
import { Auth } from "aws-amplify";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Routes from "./Routes";
import { AppContext } from "./context/appContext";
import { getRequestedSession, getProcessedSession } from "./helpers/getSession";
import background from "./images/background.jpg";
import { colours } from "./defaults/colours";
import reducer, { actions, initialState } from "./appReducer";
import "./App.css";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colours.primary,
    },
    secondary: {
      main: colours.secondary,
    },
  },
});

function App() {
  const [
    {
      isAuthenticated,
      isAuthenticating,
      requestedFile,
      processedFile,
      userFiles,
      allFiles,
      activeFile,
      refetchTopFive,
      refetchAllFiles,
      userOptIn,
      nav,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const userHasAuthenticated = (props) =>
    dispatch({ type: actions.AUTHENTICATE, payload: props });
  const setIsAuthenticating = (props) =>
    dispatch({ type: actions.AUTHENTICATING, payload: props });
  const setRequestedFile = (props) =>
    dispatch({ type: actions.REQUEST_FILE, payload: props });
  const setProcessedFile = (props) =>
    dispatch({ type: actions.PROCESS_FILE, payload: props });
  const setUserFiles = (props) =>
    dispatch({ type: actions.USER_FILES, payload: props });
  const setAllFiles = (props) =>
    dispatch({ type: actions.ALL_FILES, payload: props });
  const setActiveFile = (props) =>
    dispatch({ type: actions.ACTIVE_FILE, payload: props });
  const setRefetchTopFive = (props) =>
    dispatch({ type: actions.REFETCH_FIVE, payload: props });
  const setRefetchAllFiles = (props) =>
    dispatch({ type: actions.REFETCH_ALL, payload: props });
  const setUserOptIn = (props) =>
    dispatch({ type: actions.USER_OPT, payload: props });
  const setNav = (props) => dispatch({ type: actions.NAV, payload: props });
  const clearContext = () =>
    dispatch({ type: actions.CLEAR });

  useEffect(() => {
    onLoad();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function onLoad() {
    try {
      const session = localStorage.getItem("session");
      if (session) {
        const sessionData = JSON.parse(session);
        if (new Date().getTime() > sessionData.expiry) {
          localStorage.removeItem("session");
          await Auth.signOut();
        }
      }
      await Auth.currentSession();
      userHasAuthenticated(true);
      setRequestedFile(
        getRequestedSession({
          file: null,
          fileName: null,
          filePrice: 0,
        })
      );
      setProcessedFile(
        getProcessedSession({
          fileName: null,
          filePath: null,
        })
      );
    } catch (e) {
      if (e !== "No current user") {
        alert(e.message);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    <div
      className="App"
      style={{
        backgroundImage: `url("${background}")`,
      }}
    >
      <AppContext.Provider
        value={{
          isAuthenticated,
          userHasAuthenticated,
          nav,
          setNav,
          requestedFile,
          setRequestedFile,
          processedFile,
          setProcessedFile,
          userFiles,
          setUserFiles,
          allFiles,
          setAllFiles,
          refetchTopFive,
          setRefetchTopFive,
          refetchAllFiles,
          setRefetchAllFiles,
          activeFile,
          setActiveFile,
          userOptIn,
          setUserOptIn,
          clearContext,
        }}
      >
        <ThemeProvider theme={theme}>
          {!isAuthenticating && <Routes />}
        </ThemeProvider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
