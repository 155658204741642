import { Switch, Route } from "react-router-dom";
import loadable from "@loadable/component";
import AuthenticatedRoute from "./components/Routes/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/Routes/UnauthenticatedRoute";

const SignInSide = loadable(() => import("./containers/SignInSide/SignInSide"));
const SignUp = loadable(() => import("./containers/SignUp/SignUp"));
const ResetPassword = loadable(() =>
  import("./containers/ResetPassword/ResetPassword")
);
const Dashboard = loadable(() => import("./containers/Dashboard/Dashboard"));
const NotFound = loadable(() => import("./containers/NotFound/NotFound"));
const Settings = loadable(() => import("./containers/Settings/Settings"));

export default function Routes() {
  return (
    <Switch>
      <AuthenticatedRoute exact path="/">
        <Dashboard />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute>
      <UnauthenticatedRoute exact path="/login">
        <SignInSide />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <SignUp />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/reset-password">
        <ResetPassword />
      </UnauthenticatedRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
